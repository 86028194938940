import React, { useState } from 'react';
import { Carousel, Rate, Input, Button } from 'antd';
import { LeftOutlined, RightOutlined, SearchOutlined } from '@ant-design/icons';
import Link from 'next/link';
import Image from 'next/image';
import usePrefix from '@/hooks/usePrefix';

const { Search } = Input;

function AirportCarouselHomeB({ items, deviceType }) {
  const prefix = usePrefix();
  const [searchTerm, setSearchTerm] = useState('');
  const [sortType, setSortType] = useState('name');
  const [activeIndex, setActiveIndex] = useState(0);

  const handleAfterChange = (current: number) => {
    setActiveIndex(current);
  };

  const filteredItems = items
    .filter((item) =>
      item.name.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .sort((a, b) => {
      if (sortType === 'name') {
        return a.name.localeCompare(b.name);
      } else if (sortType === 'price') {
        return a.price - b.price;
      }
      return 0;
    });

  return (
    <div className="flex flex-col mt-12">
      <h2 className="text-center text-4xl md:text-6xl mb-4 font-bold">
        Most popular discounted parking
      </h2>
      <p className="text-center text-xl md:text-2xl mb-6">
        Discover more in this section and find the best deal for you
      </p>

      <div className="pb-4 md:pb-8 flex flex-col">
        <Carousel
          effect="scrollx"
          autoplay
          infinite={true}
          slidesToShow={deviceType === 'desktop' ? 3 : 1}
          slidesToScroll={1}
          className="overflow-visible pb-4 px-6 md:px-28"
          speed={500}
          arrows
          prevArrow={<LeftOutlined />}
          nextArrow={<RightOutlined />}
          afterChange={handleAfterChange}
          centerMode={deviceType === 'desktop' ? true : false}
        >
          {filteredItems.map((item, index) => (
            <Link
              href={prefix + `/parking/${item.hrefCard}`}
              key={index}
              className={`w-full h-80 border overflow-hidden relative rounded-t-lg rounded-b-lg md:flex flex-col cursor-pointer carousel-card ${
                index === activeIndex ? 'active' : 'inactive'
              }`}
            >
              <div
                className="relative flex flex-col items-center justify-center w-full h-full bg-no-repeat bg-center rounded-t-md bg-cover"
                title={`View ${item.name}`}
              >
                <Image
                  src={item.imgCard}
                  fill
                  sizes="100vw, 0vw"
                  blurDataURL={item.imgCard}
                  placeholder="blur"
                  quality={70}
                  className="z-30 rounded-t-md"
                  priority
                  alt={`View ${item.name}`}
                />
                <div className="absolute bottom-0 left-0 right-0 p-2 z-50 bg-primary bg-opacity-60 pb-4">
                  <h3
                    className={`flex justify-center items-center h-10 text-white  font-bold mb-2  ${
                      index === activeIndex
                        ? 'xl:text-4xl lg:text-2xl text-xl'
                        : 'xl:text-3xl lg:text-xl text-lg'
                    }`}
                  >
                    {item.name}
                  </h3>
                  <h3 className="flex justify-center items-center text-white font-bold">
                    <span
                      className={`font-semibold mr-2 
                    ${
                      index === activeIndex
                        ? 'xl:text-3xl lg:text-xl text-base'
                        : 'xl:text-2xl lg:text-lg text-base'
                    }`}
                    >
                      As low as
                    </span>
                    <span
                      className={`xl:text-4xl lg:text-2xl md:text-lg font-bold text-white
                      ${
                        index === activeIndex
                          ? 'xl:text-4xl lg:text-2xl text-lg'
                          : 'xl:text-3xl lg:text-xl text-lg'
                      }`}
                    >
                      ${item.price}
                    </span>
                    <span
                      className={`font-semibold ml-2 
                    ${
                      index === activeIndex
                        ? 'xl:text-3xl lg:text-xl text-base'
                        : 'xl:text-2xl lg:text-lg text-base'
                    }`}
                    >
                      /day
                    </span>
                  </h3>
                </div>
              </div>
            </Link>
          ))}
        </Carousel>
      </div>
    </div>
  );
}

export default AirportCarouselHomeB;
